import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { useCopyToClipboard, useDelayedState } from 'utils/hooks';

import { emptyGoal, deleteGoal, updateGoalField } from 'store/actions/goals';
import { changeActiveTemplate } from 'store/actions/templates';

import Simple from 'components/Checkboxes/components/Simple';
import { openAlert } from 'components/Alert/utils';
import Button from 'components/Button';
import Text from 'components/Text';

import { ReactComponent as EditPencil } from 'assets/icons/edit-pencil.svg';
import { ReactComponent as TrashBinIcon } from 'assets/icons/trashbin.svg';
import Link from 'assets/icons/components/Link';

import CopiedText from 'styles/CopiedText';
import WhiteBox from 'styles/WhiteBox';

import {
  BlueDot,
  GoalContainer,
  GoalTitle,
  ToolsContainer,
  RoundedCurrencyInput,
  RoundedTextInput,
  SwitchContainer,
  SettingsButton,
  TemplateSelector,
  Preview,
} from './index';

const Wrapper = styled(WhiteBox)`
  padding: 35px 30px;
  position: relative;
`;

const Label = styled(Text)`
  white-space: nowrap;
`;

const Goal = ({ index, data, history, inVoting }) => {
  const {
    id,
    title,
    target,
    initial_value: initialAmount,
    without_commission: withoutCommission,
    template_id: template,
    is_default: isDefault,
  } = data;

  const dispatch = useDispatch();
  const currentUserId = useSelector(state => state.userData.info.id);
  const [copied, copyLink] = useCopyToClipboard();

  const handleChange = (fieldName, newValue) => {
    dispatch(updateGoalField(id, fieldName, newValue));
  };

  const [innerTitle, setInnerTitle] = useDelayedState(title, 'title', handleChange);
  const [innerTarget, setInnerTarget] = useDelayedState(target, 'target', handleChange);
  const [innerInitialAmount, setInnerInitialAmount] = useDelayedState(
    initialAmount,
    'initial_value',
    handleChange,
  );

  const assembleGoalUrl = (isPreview = true) =>
    `${process.env.REACT_APP_WIDGETS_URL}/TIPS_GOAL/${currentUserId}/GOAL/${id}${
      isPreview ? '?previewMode&v=1' : ''
    }`;

  const handleCopyGoalUrl = () => {
    copyLink(assembleGoalUrl(false));
  };

  const handleEditTemplate = () => {
    dispatch(changeActiveTemplate(data.template_id, 'TIPS_GOAL'));
    history.push('/konfigurator/twoj-cel');
  };

  const handleRemove = () => {
    dispatch(deleteGoal(id));
  };

  const handleEmpty = () => {
    openAlert(
      'confirm',
      'Czy na pewno?',
      'Czy na pewno chcesz usunąć progres zebranego celu? Zostanie usunięta kwota zebrana przez widzów od początku istnienia celu oraz kwota dodana niestandardowo. Przemyśl to, bo nie ma odwrotu!',
      {
        cancelButtonText: 'Anuluj proces',
        confirmButtonText: 'Lecimy z tym',
        cancelButton: true,
      },
      () => {
        dispatch(emptyGoal(id));
      },
    );
  };

  return (
    <Wrapper>
      <GoalContainer>
        <BlueDot />
        <div>
          <GoalTitle>
            {`Twój cel ${index + 1}`}
            {isDefault && ' - Cel domyślny'}
          </GoalTitle>
          <ToolsContainer>
            <div style={{ flexGrow: 1 }}>
              <Label>Wpisz tytuł swojego celu na który będziesz zbierał</Label>
              <RoundedTextInput
                value={innerTitle}
                onChange={e => setInnerTitle(e.target.value)}
                maxLength={64}
              />
            </div>
            <div>
              <Label>Kwota do zebrania</Label>
              <RoundedCurrencyInput
                value={innerTarget / 100}
                onValueChange={e => setInnerTarget((e.floatValue || 0) * 100)}
                maxLength={11}
              />
            </div>
            <div>
              <Label>Dodaj swoją kwotę do celu</Label>
              <RoundedCurrencyInput
                value={innerInitialAmount / 100}
                onValueChange={e => setInnerInitialAmount((e.floatValue || 0) * 100)}
                maxLength={11}
              />
            </div>
            <div>
              <Label>Wyświetlaj kwotę po opłatach</Label>
              <SwitchContainer>
                <Simple
                  apperance="switch"
                  checked={withoutCommission}
                  onCheck={check => handleChange('without_commission', check)}
                  color="green"
                />
              </SwitchContainer>
            </div>
            <div style={{ paddingLeft: 35, position: 'relative' }}>
              <SettingsButton
                onEditTemplate={handleEditTemplate}
                onRemove={handleRemove}
                removeDisabled={isDefault}
              />
            </div>
          </ToolsContainer>
          <Preview
            widgetUrl={assembleGoalUrl()}
            enabled={index === 0}
            withVotingWarning={inVoting}
          />
          <ToolsContainer>
            <div>
              <TemplateSelector
                selected={template}
                onChange={e => handleChange('template_id', e)}
              />
            </div>
            <div>
              <Button background="gray" icon={<EditPencil />} onClick={handleEditTemplate}>
                Edytuj wygląd celu
              </Button>
            </div>
            <div style={{ position: 'relative' }}>
              <CopiedText copied={copied}>Skopiowano</CopiedText>
              <Button
                active={copied}
                onClick={handleCopyGoalUrl}
                background="blue"
                icon={<Link />}
                type="button"
              >
                Skopiuj URL
              </Button>
            </div>
            <div style={{ flexGrow: 1 }}>
              <Button onClick={handleEmpty} icon={<TrashBinIcon />} background="light-red" block>
                Opróżnij cel z zarobków
              </Button>
            </div>
          </ToolsContainer>
        </div>
      </GoalContainer>
    </Wrapper>
  );
};

Goal.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    template_id: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.number,
    initial_value: PropTypes.number,
    without_commission: PropTypes.bool,
    is_default: PropTypes.bool,
  }).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  inVoting: PropTypes.bool.isRequired,
};

export default withRouter(Goal);
